import React from 'react'
import { useSelector } from 'react-redux'
import SEO from 'components/SEO/SEO'
import translations from 'assets/translations/translations'
import PageHeader from 'components/Typography/Headers/PageHeader'
import PageIntro from 'components/PageIntro/PageIntro'
import Footer from 'components/Footer/Footer'
import MarginBottom from 'components/UI/MarginBottom/MarginBottom'
import styled from 'styled-components'
import MEDIA from 'utils/helpers/media'

const Container = styled.div`
  margin-top: -50px;
  ${MEDIA.MIN_TABLET`
    margin-top: -100px;
    `}
  ${MEDIA.MIN_DESKTOP`
    margin-top: -150px;
    `}
    & p {
    margin: 10px 0 10px 0;
  }
`

const MainContainer = styled.div`
  ${MEDIA.MIN_BIGGESTDESKTOP`
     min-height: calc(100vh - 500px);
    `}
`

const Prywatnosc = () => {
  const location = useSelector(({ state }) => state.targetRoute)

  return (
    <>
      <SEO title={translations.pages.contact} pathname={location.pathname} />
      <MainContainer>
        <PageIntro>
          <PageHeader>{translations.headers.privacy.privacy}</PageHeader>
          <PageHeader blue>{translations.headers.privacy.policy}</PageHeader>
        </PageIntro>
        <Container>
          <b>Polityka prywatności serwisu www.kancelariamichalak.com</b> <br />
          <p>
            1. Niniejsza Polityka Prywatności określa zasady przetwarzania i
            ochrony danych osobowych przekazanych przez Użytkowników w związku z
            korzystaniem przez nich z serwisu www.kancelariamichalak.com
          </p>
          <p>
            2. Administratorem danych osobowych zawartych w serwisie jest
            Michalak Kancelaria Podatkowo-Księgowa Sp. z o.o. z siedzibą w
            Siedlcach KRS 0000508579 NIP 8212640820 REGON 14723784000000
          </p>
          <p>
            3. W trosce o bezpieczeństwo powierzonych nam danych opracowaliśmy
            wewnętrzne procedury i zalecenia, które mają zapobiec udostępnieniu
            danych osobom nieupoważnionym. Kontrolujemy ich wykonywanie i stale
            sprawdzamy ich zgodność z odpowiednimi aktami prawnymi - ustawą o
            ochronie danych osobowych, ustawą o świadczeniu usług drogą
            elektroniczną, a także wszelkiego rodzaju aktach wykonawczych i
            aktach prawa wspólnotowego.
          </p>
          <p>
            4. Dane Osobowe przetwarzane są na podstawie zgody wyrażanej przez
            Użytkownika oraz w przypadkach, w których przepisy prawa upoważniają
            Administratora do przetwarzania danych osobowych na podstawie
            przepisów prawa lub w celu realizacji zawartej pomiędzy stronami
            umowy.
          </p>
          <p>
            5. Serwis realizuje funkcje pozyskiwania informacji o użytkownikach
            i ich zachowaniach w następujący sposób: a) poprzez dobrowolnie
            wprowadzone w formularzach informacje b) poprzez gromadzenie plików
            “cookies”.
          </p>
          <p>
            6. Serwis zbiera informacje dobrowolnie podane przez użytkownika.
          </p>
          <p>
            7. dane podane w formularzu są przetwarzane w celu wynikającym z
            funkcji konkretnego formularza np. w celu dokonania procesu obsługi
            kontaktu informacyjnego
          </p>
          <p>
            8. Dane osobowe pozostawione w serwisie nie zostaną sprzedane ani
            udostępnione osobom trzecim, zgodnie z przepisami Ustawy o ochronie
            danych osobowych.
          </p>
          <p>
            9. Do danych zawartych w formularzu przysługuje wgląd osobie
            fizycznej, która je tam umieściła. Osoba ta ma również praw do
            modyfikacji i zaprzestania przetwarzania swoich danych w dowolnym
            momencie.
          </p>
          <p>
            10. Zastrzegamy sobie prawo do zmiany w polityce ochrony prywatności
            serwisu, na które może wpłynąć rozwój technologii internetowej,
            ewentualne zmiany prawa w zakresie ochrony danych osobowych oraz
            rozwój naszego serwisu internetowego. O wszelkich zmianach będziemy
            informować w sposób widoczny i zrozumiały.
          </p>
          <p>
            11. W Serwisie mogą pojawiać się linki do innych stron
            internetowych. Takie strony internetowe działają niezależnie od
            Serwisu i nie są w żaden sposób nadzorowane przez serwis
            www.kancelariamichalak.com Strony te mogą posiadać własne polityki
            dotyczące prywatności oraz regulaminy, z którymi zalecamy się
            zapoznać.
          </p>
          <p>
            W razie wątpliwości co któregokolwiek z zapisów niniejszej polityki
            prywatności jesteśmy do dyspozycji - nasze dane znaleźć można w
            zakładce - KONTAKT.
          </p>
        </Container>
        <MarginBottom />
      </MainContainer>
      <Footer />
    </>
  )
}

export default Prywatnosc
